.container { 
    /* height: 150px; */
    width: 100%;
    /* border: 1px solid black; */
}

.listPrice{
    font-size: 40px;
    color: #151aae;
    font-weight: 600;
    font-family: montserratSemiBold;
}

.UnparssedAddress {
    font-size: 32px;
    color: #17171a;
    font-weight: 500;
    font-family: montserratMedium;
    margin-top: 15px;
}

.itemContainer {
    display: flex;
    justify-content: start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 80%;
}
