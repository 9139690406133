.container {
    padding-top: 40px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    background-color: #fff;
    /* border: 1px solid black; */
    height: 80%;
    width: 85%;
}

.headerTitle {
    margin: 0;
    color: #151aae;
    font-size: 25px;
    font-family: montserratBold;
    font-weight: bold;
    margin-top: 5px;    
    margin-left: 8.5px;
}

.titleContainer {
    position: relative;
    display: flex;
    align-items: center;
}

.termsAndConditionsContainer {
    margin-top: 30px;
    /* border: 1px solid black; */
}

.checkboxContainer {
    margin-top: 44px;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


.scrollBar > p {
    font-weight: bold;
    font-family: montserrat;
    font-size: 16px;
}

.scrollBar > p > span {
    font-weight: normal;
    font-family: montserrat;
    font-size: 16x;
}