.container {
    border: 1px solid #e4e0e0;
    padding: 7px;
    border-radius: 8px;
    margin-top: 15px;
    cursor: pointer;
}

.listingImage {
    width: 100%;
    border-radius: 8px;
}


.unparsedAddress {
    font-family: montserratMedium;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color:#17171a;
    margin-top: 12px;
}

.publicRemarks {
    /* color: #6f6c6c; */
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    font-family: montserratLight;
    margin-top: 4px;
}

.listPrice {
    font-family: montserratSemiBold;
    color: #17171a;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin-top: 13px;
}


.iconsContainer {
    display: flex;
    flex-direction: row;
    margin-top: 13px;
}

.iconsContainer span {
    color: #6f6c6c; 
    font-family: montserratMedium;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;   
}