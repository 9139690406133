.container { 
    height: 326px;
    background-color: #F3F4FA;
    border-radius: 10px;
    padding: 22px;
}

.activeListing {
    color: #323233;
    font-size: 18px;
    text-align: left;
    font-family: montserrat;
    background-color: rgba(29, 104, 249, 0.09);
    padding: 8px;
    margin-top: 20px;
}