.container {
    /* border: 1px solid black; */
    width: 1920px;
}

.header {
    height: 100px;
    /* border: 1px solid green; */
    background-image: linear-gradient(to left, #7106c4 100%, #19257c 0%);
}

.headerContent {
    width: 1920px;
    /* border: 1px solid red; */
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainContainer {
    width: 1920px;
    /* height: 1000px; */
    margin: 0 auto;
    /* border: 1px solid black; */
}

.content {
    width: 1920px;
    /* border: 1px solid black; */
    height: 100%;
    margin: 0 auto;
}

.twoCol {
  display: flex;
  justify-content: space-between;  
  margin-top: 37px;
}

.left {
    width: 75%;
    /* border: 1px solid black; */
    /* height: 600px; */
}

.right {
    width: 25%;
    /* border: 1px solid black; */
    /* height: 600px; */
    padding-left: 28px;
}

.footer {
    height: 100px;
    background-color: #F3F4FA;
    /* border: 1px solid black; */
    box-shadow: 0px 0px 1px 1px #ccc;

}

.footerContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 1528px;
    margin: 0 auto;
}

.footerLink {
    font-size: 16px;
    font-family: montserratLight;
    font-weight: 300;
    color: #17171a;
    text-decoration: none;
}

.Imagecontainer {
    height: 540px;
    /* border: 1px solid black; */
    /* width: 1000px; */
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}
.mainImage {
    width: 100%;
    height: 540px;
    border-radius: 7px;
}

.smallImage {
    width: 361px;
    height: 256px;
    border-radius: 7px;
}
.listingInfoContainer { 
    /* height: 150px; */
    width: 100%;
    /* border: 1px solid black; */
}

.listPrice{
    font-size: 40px;
    color: #151aae;
    font-weight: 600;
    font-family: montserratSemiBold;
}

.UnparssedAddress {
    font-size: 32px;
    color: #17171a;
    font-weight: 500;
    font-family: montserratMedium;
    margin-top: 20px;
}

.itemContainer {
    display: flex;
    justify-content: start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 80%;
}



.description {
    color: #808080;
    font-family: montserrat;
    font-size: 22px;
    margin-top: 26px;
}
.text {
    color: #323233;
    font-family: montserratSemiBold;
    font-size: 32px;
    font-weight: 600;
    margin: 0;
    margin-top: 50px;
}
.agentContainer { 
    height: 350px;
    background-color: #F3F4FA;
    border-radius: 10px;
    /* padding: 22px; */
}

.activeListing {
    color: #323233;
    font-size: 18px;
    text-align: left;
    font-family: montserrat;
    background-color: rgba(29, 104, 249, 0.09);
    padding: 8px;
    margin-top: 20px;
    border-radius: 4px;
}
.contentContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 11px;
}

.agentImage { 
    width: 104px;
    height: 104px;
    border-radius: 6px;
}

.agentName {
    font-size: 22px;
    font-family: montserratSemiBold;
    color:#17171a;
    font-weight: 600;
    text-align: left;   
}

.agentDescription {
  font-family: montserratLight;
  color: #808080;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  width: 197px; 
  margin-top: 7px;
}
.officeContainer { 
    height: 235px;
    background-color: #F3F4FA;
    border-radius: 10px;
    padding: 22px;
    margin-top: 20px;
    box-sizing: border-box;
}
.officeText { 
    color: #17171a;
    font-size: 23px;
    font-weight: 600;
    font-family: montserratSemiBold;
    text-align: left;
    padding: 0;
    margin: 0;
}


@media only screen and (max-width: 1920px) {
    .container {
        width: 100%;
    }
    .headerContent {
        width: 100%;
    }
    .mainContainer{
        width: 100% !important;
    }
    .content{
        width: 90%;
        /* border: 1px solid black; */
    }
    .footerContent{
        width: 90%;

    }

  }