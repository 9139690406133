
.container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;
    width: 33%;
    /* flex-grow: 1; */
}

.key {
    color: #808080;
    font-size: 22px;
    font-family: montserrat;
    margin-left: 8px;
}

.value {
    color: #323233;
    font-family: montserratSemiBold;
    font-size: 22px;
    padding-left: 8px;
    font-weight: 600;
}
.text{
    padding:0;
    margin: 0;
    display: flex;
    align-items:center;
     
}