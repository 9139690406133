.container {
    width: 330px;
    /* border: 1px solid black;    */
    cursor: pointer;
}

.image {
    width: 100%;
    height: 190px;
}

.UnparssedAddress {
    color: #17171a;
    font-size: 15px;
    font-weight: 500;
    font-family: montserratMedium;
    margin-top: 10px;
}

.PublicRemarks {
    font-size: 14px;
    color: #808080;
    font-family: montserratLight;
    margin-top: 2px;
    font-weight: 300;
}

.ListPrice {
    font-size: 18px;
    font-family: montserratSemiBold;
    color: #17171a;
    margin-top: 12px;
}

.iconContainer {
    display: flex;
    justify-content: flex-start;
    margin-top: 12px;
}

.iconContainer > p {
    font-size: 15px;
    color: #323233;
    font-family: montserratMedium;

}

.bedroomIcon {
    width: 24px;
    height: 22px;
    background: url('../../../assets/svg/bed.svg') no-repeat center center;
}

.bathroomsIcon {
    width: 24px;
    height: 22px;
    background: url('../../../assets/svg/bath.svg') no-repeat center center;
    margin-left: 24px;
}

.buildingAreaIcon {
    width: 24px;
    height: 22px;
    background: url('../../../assets/svg/floor_area.svg') no-repeat center center;
    margin-left: 24px;
}