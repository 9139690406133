.container {
    height: 540px;
    /* border: 1px solid black; */
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}
.mainImage {
    width: 100%;
    height: 540px;
    border-radius: 7px;
}

.smallImage {
    width: 361px;
    height: 256px;
    border-radius: 7px;
}



@media only screen and (max-width: 1920px) {
   .smallImage {
    width: 351px;
    height: 256px;
   }
  }

  @media only screen and (max-width: 1700px) {
    .smallImage {
     width: 48%;
     height: 256px;
    }
}

@media only screen and (max-width: 1500px) {
    .smallImage {
     width: 46%;
     height: 256px;
    }
}