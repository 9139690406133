.container {
    background-color: white;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    align-items: center;
}

.container > .image {
    width: 82px;
    height: 82px;
    border-radius: 100%;
    border: 1px solid #dfdfdf;
}

.container > .rightCol  {
    margin-left: 13px;
}

.container > .rightCol > .fullName {
    color: #000000;
    font-size: 16px;
    font-family: montserratSemiBold;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.container > .rightCol > .bio {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    font-family: montserratRegular;
    margin-top: 7.5px;
    color: #6f6c6c;
}