.container {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: start;
}

.container a {
    text-decoration: none;
}


.socialLinkContainer {
    width: 53.3px;
}

.socialLinkTitle {
    font-family: montserratRegular;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    text-align: center;
    margin-top: 6.3px;
    
}

.socialLogo {
    width: 53.3px;
    height: 53.3px;
    border-radius: 100%;

}

.marginLeft {
    margin-left: 18.4px;
}