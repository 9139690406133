.container {
    margin-top: 20px;
    padding-bottom: 20px;
}

.title {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    font-family: montserratSemiBold;
}

.spinnerContainer {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}