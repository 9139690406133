.text {
    color: #151aae;
    font-family: montserratMedium;
    font-size: 18px;
    font-weight: 500;
    margin-left: 22.3px;
}
.container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
