.row {
    display: flex;
    justify-content: flex-start;
    width: 49%;
    /* flex-grow: 1; */
    margin-top: 20px;
    /* border-bottom: 1px solid rgb(172, 41, 41); */
    /* height: 34px; */
}

.key {
    text-align: left;
    width: 50%;
    flex-grow: 1;
    color: #808080;
    font-family: montserrat;
    font-size: 22px;
}

.value {
    text-align: left;
    width: 50%;
    flex-grow: 1;
    color: #323233;
    font-family: montserratSemiBold;
    font-size: 22px;
    font-weight: 600;
}