.contentContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 11px;
}

.agentImage { 
    width: 104px;
    height: 104px;
    border-radius: 6px;
}

.agentName {
    font-size: 22px;
    font-family: montserratSemiBold;
    color:#17171a;
    font-weight: 600;
    text-align: left;   
}

.agentDescription {
  font-family: montserratLight;
  color: #808080;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  width: 197px; 
  margin-top: 7px;
}