.container {
    /* border: 1px solid black; */
    /* width: 1920px; */
    margin: 0 auto;
}

.header {
    height: 100px;
    /* border: 1px solid green; */
    background-image: linear-gradient(to left, #7106c4 100%, #19257c 0%);
}

.headerContent {
    width: 1920px;
    /* border: 1px solid red; */
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: start;
    align-items: center;
}

.mainContainer {
    width: 1920px;
    /* height: 1000px; */
    margin: 0 auto;
    /* border: 1px solid black; */
}

.content {
    width: 1528px;
    /* border: 1px solid black; */
    height: 100%;
    margin: 0 auto;
}

.twoCol {
  display: flex;
  justify-content: space-between;  
  margin-top: 37px;
}

.left {
    width: 75%;
    /* border: 1px solid black; */
    /* height: 600px; */
}

.right {
    width: 25%;
    /* border: 1px solid black; */
    /* height: 600px; */
    padding-left: 28px;
}

.footer {
    height: 100px;
    background-color: #F3F4FA;
    /* border: 1px solid black; */
    box-shadow: 0px 0px 1px 1px #ccc;

}

.footerContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 1528px;
    margin: 0 auto;
}

.footerLink {
    font-size: 16px;
    font-family: montserratLight;
    font-weight: 300;
    color: #17171a;
    text-decoration: none;
}


@media only screen and (max-width: 1920px) {
    .headerContent {
        width: 95%;
    }
    .mainContainer{
        width: 100%;
    }
    .content{
        width: 90%;
    }
    .footerContent{
        width: 90%;
    }
  }