.container { 
    /* height: 150px; */
    width: 100%;
    /* border: 1px solid black; */
}

.description {
    color: #808080;
    font-family: montserrat;
    font-size: 22px;
    margin-top: 26px;
}