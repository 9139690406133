.container {
    width: 100%;
    background-color: #151aae;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.container>p {
    color: #fff;
}