.container { 
    /* height: 150px; */
    width: 100%;
    /* border: 1px solid black; */
}

.contentContainer {
    display: flex;
    /* justify-content: center; */
    flex-direction: row;
    width: 100%;
    margin-top: 26px;
    flex-wrap: wrap;
}

.leftCol {
    flex:1;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.leftCol > div {
    width: 50%;
    /* flex-grow: 1; */
}

.rightCol {
    flex: 1; 
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rightCol > div {
    width: 50%;
    /* flex-grow: 1; */
}