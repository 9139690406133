.container { 
    /* height: 215px; */
    background-color: #F3F4FA;
    border-radius: 10px;
    padding: 22px;
    margin-top: 20px;
    box-sizing: border-box;
}

.betterConnect {
    color: #323233;
    font-family: montserratBold;
    font-size: 15px;
    text-align: center;
    font-weight: bold;
}

.print {
    color: #151aae;
    font-family: montserratBld;
    font-size: 16px;
    text-align: center;
    cursor: pointer;

}

