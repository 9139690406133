.text {
    color: #17171a;
    font-family: montserrat;
    font-size: 19px;
    font-weight: 500;
    margin-left: 10px;
}
.container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
